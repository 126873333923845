<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>About Reinit Market Research</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Reinit Market Research</span>
                    <h2>We Help You Meet Your Goals</h2>
                    <p>At Reinit Research we focus on developing innovative responses towards both business and social challenges through provision of Market Research, Data Collection and Data Analysis. Reinit Research team has wide consulting experience in the African region in addition to hosting one of the largest consumer audience in Africa.</p>
                    <p>As a full market research firm, we are inspired by the pursuit of integrity, excellence, accountability, collaboration and the passion for doing things differently with a lasting positive impact on individuals, businesses and communities. Our vision is to be the most respected, trusted, cost-effective and sought-after research firm by our clients and employees.</p>
                    <ul class="funfacts-list">
                        <li>
                            <div class="list">
                                <i class="flaticon-menu-1"></i>
                                <h3 class="odometer" data-count="10215">00</h3>
                                <p>Completed Surveys</p>
                            </div>
                        </li>
                        <li>
                            <div class="list">
                                <i class="flaticon-web-settings"></i>
                                <h3 class="odometer" data-count="5125418">00</h3>
                                <p>Responses Received</p>
                            </div>
                        </li>
                        <li>
                            <div class="list">
                                <i class="flaticon-conversation"></i>
                                <h3 class="odometer" data-count="402548">00</h3>
                                <p>Total Panelists</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area ptb-100">
    <app-feedback></app-feedback>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</section>


<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>
