<div class="container">
    <div class="section-title">
        <!-- <span class="sub-title">Featured</span> -->
        <!-- <h2>Empowering Business With information</h2> -->
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> -->
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-featured-services-box">
                <div class="icon">
                    <i class="flaticon-research"></i>
                    <div class="circles-box">
                        <div class="circle-one"></div>
                        <div class="circle-two"></div>
                    </div>
                </div>
                <h3><a routerLink="/single-services">Market Research</a></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="single-featured-services-box">
                <div class="icon">
                    <i class="flaticon-speed"></i>
                    <div class="circles-box">
                        <div class="circle-one"></div>
                        <div class="circle-two"></div>
                    </div>
                </div>
                <h3><a routerLink="/single-services">Social Research</a></h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                <a routerLink="/single-services" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
            </div>
        </div>
    </div>
</div>
