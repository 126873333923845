<section class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">About Market Research</span>
                        <h2>Empowering Business With Information</h2>
                        <p>Reinit Research provides research and training services to institutions in the public and the private sectors.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-speed"></i> We profile our panelists using detailed background information therefore giving you a wide array of options to choose from according to your needs</li>
                            <li><i class="flaticon-speed"></i> We deliver quality, unbiased, market research data tailored to the needs of your business with our unique combination of expertise and reach.</li>
                            <li><i class="flaticon-speed"></i> We are a corporate member of ESOMAR and as such have fully complied with the set standards for online research..</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
