<section class="testimonials-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="testimonials-content">
                    <span class="sub-title">Testimonials</span>
                    <h2>What Are Our Clients Saying?</h2>

                    <div class="testimonials-slides owl-carousel owl-theme">
                        <div class="single-testimonials-item">
                            <p> We engaged Reinit Research for a customer satisfaction survey and they delivered promptly and their cost was friendly. I would recommend them to anyone.</p>

                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="title">
                                        <h3>Gordon</h3>
                                        <span>Executive Director - Herufi Africa</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="single-testimonials-item">
                            <p>We are proud to collaborate with Reinit Research in surveys. What we love about them is that they are professional and ensure quality during data collection and analysis.</p>

                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="title">
                                        <h3>Muthoni</h3>
                                        <span>Lead Partner - Enrich Research Hut</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="single-testimonials-item">
                            <p>The insurance industry is dynamic and so we were looking for a research firm that would keep us informed at all times. Reinit Research offers us tailor made services that inform our business decisions.</p>

                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Juliet</h3>
                                        <span>CEO - Riley Insurance
                                            Agency</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="testimonials-image">
                    <img src="assets/img/testimonials-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
