<div class="container">
    <h2>Our Clients</h2>
    <div class="row align-items-center">
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-87.png" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-88.svg" alt="image">
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-92.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-90.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img style="color:black" src="assets/img/partner/partner-93.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img style="color:black" src="assets/img/partner/partner-94.jpg" alt="image">
            </div>
        </div>
    </div>
</div>
