<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Contact Us</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Our Address</h3>
                    <p>Mogotio Rd, Westlands

                        Royal Offices, 1st Floor, Room 18A Nairobi Kenya</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Contact</h3>
                    <p>Mobile: <a href="tel:+254780058536">+254 780 058 536</a></p>
                    <p>E-mail: <a href="mailto:rfp@reinitresearch.com">rfp@reinitresearch.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-time-five'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-time-five'></i>
                    </div>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 9:00AM - 5:00PM</p>
                    <p>Saturday: 10:30AM - 2:00PM</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Get in Touch</span>
            <h2>Ready to Get Started?</h2>
            <p>Your email address will not be published. Required fields are marked *</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/img/contactt.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="Form" (ngSubmit)="submitForm()">
                        <div *ngIf="message" class="alert alert-success alert-dismissible fade show" role="alert">
                            {{ message }}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
                            {{ error }}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="name" class="form-control" id="name" required
                                        data-error="Please enter your name" placeholder="Your name">
                                    <div class="form-control-feedback"
                                        *ngIf="name.errors && (name.dirty || name.touched)">
                                        <p *ngIf="name.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                        <p *ngIf="name.errors.pattern">
                                            <span style="color: red;">Invalid name</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" id="email" required
                                        data-error="Please enter your email" placeholder="Your email address">
                                    <div class="form-control-feedback"
                                        *ngIf="email.errors && (email.dirty || email.touched)">
                                        <p *ngIf="email.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                        <p *ngIf="email.errors.email">
                                            <span style="color: red;">Invalid email address</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="subject" class="form-control" id="name" required
                                        data-error="Please enter the subject" placeholder="Subject">

                                    <div class="form-control-feedback"
                                        *ngIf="subj.errors && (subj.dirty || subj.touched)">
                                        <p *ngIf="subj.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" id="message" class="form-control" cols="30"
                                        rows="6" required data-error="Please enter your message"
                                        placeholder="Write your message..."></textarea>

                                    <div class="form-control-feedback"
                                        *ngIf="mess.errors && (mess.dirty || mess.touched)">
                                        <span style="color: red;" *ngIf="mess.errors.required">This field is
                                            required</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="loading">
                                <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span> Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8420487875305!2d36.807729614325105!3d-1.2675212359624426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f173c2cc63abf%3A0x8a56926007976f30!2sRoyal%20Offices!5e0!3m2!1sen!2ske!4v1617974698244!5m2!1sen!2ske"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>
