
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReinitapiService {

     readonly _BaseUri = 'https://account.surveyflix.org/api';

  constructor(private http: HttpClient) { }

  contactUs(data: any) {
    return this.http.post<any>(this._BaseUri + '/send/message', data);
  }

  requestDownload(data: any) {
    return this.http.post<any>(this._BaseUri + '/download/send-request', data);
  }
}

