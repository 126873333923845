<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Request download</h1>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>


<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <h2>Request Download</h2> -->
            <p>Fill all the fields marked and you will receive the requested download in your email</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="Form" (ngSubmit)="submitForm()">
                        <div *ngIf="message" class="alert alert-success alert-dismissible fade show" role="alert">
                            {{ message }}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
                            {{ error }}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="name" class="form-control" id="name" required
                                        data-error="Please enter your name" placeholder="Your name">
                                    <div class="form-control-feedback"
                                        *ngIf="name.errors && (name.dirty || name.touched)">
                                        <p *ngIf="name.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                        <p *ngIf="name.errors.pattern">
                                            <span style="color: red;">Invalid name</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" id="email" required
                                        data-error="Please enter your email" placeholder="Your email address">
                                    <div class="form-control-feedback"
                                        *ngIf="email.errors && (email.dirty || email.touched)">
                                        <p *ngIf="email.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                        <p *ngIf="email.errors.email">
                                            <span style="color: red;">Invalid email address</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="subject" class="form-control" id="name" required
                                        data-error="Please enter the subject" placeholder="Subject">

                                    <div class="form-control-feedback"
                                        *ngIf="subj.errors && (subj.dirty || subj.touched)">
                                        <p *ngIf="subj.errors.required">
                                            <span style="color: red;">This field is required</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" id="message" class="form-control" cols="30"
                                        rows="6" required data-error="Please enter your message"
                                        placeholder="Please indicate what you wish to download"></textarea>

                                    <div class="form-control-feedback"
                                        *ngIf="mess.errors && (mess.dirty || mess.touched)">
                                        <span style="color: red;" *ngIf="mess.errors.required">This field is
                                            required</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="loading">
                                <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span> Send Request</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
