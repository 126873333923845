<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Qualitative Market Research</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title"></span>
                    <h2>Wide Range Of Qualitative Services</h2>
                    <p>Qualitative Market Research is undoubtedly one of the first step of market research. Our
                        qualitative research methods helps you gain meaningful insights
                        from the research conducted.
                    </p>
                    <div class="features-text">
                        <h4>Our Qualitative Market Research Methods</h4>
                        <p>We use wide range of qualittative market research methods and techniques that result into quality responses.</p>
                    </div>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Focus Group Discussions</span></li>
                        <li><span><i class='bx bx-check'></i> In-Depth Interviews</span></li>
                        <li><span><i class='bx bx-check'></i> Key Informant Interviews</span></li>
                        <li><span><i class='bx bx-check'></i> Ethnographic Approaches</span></li>
                        <li><span><i class='bx bx-check'></i> Online Focus Group Discussions</span></li>
                        <li><span><i class='bx bx-check'></i> Mystery Shopping</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>
