import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";


@Component({
    selector: 'app-request-download',
    templateUrl: './request-download.component.html',
    styleUrls: ['./request-download.component.scss']
})
export class RequestDownloadComponent implements OnInit {


    Form: FormGroup;
    constructor(private fb: FormBuilder, private service: ReinitapiService, private _route: ActivatedRoute) { }
    public submitted = false;
    public loading = false;
    message: string;
    error: string;
    urlParams: any = {};

    get subj() {
        return this.Form.get('subject');
    }

    get name() {
        return this.Form.get('name');
    }

    get mess() {
        return this.Form.get('message');
    }

    get email() {
        return this.Form.get('email');
    }


    ngOnInit() {
        this.urlParams.download_type = this._route.snapshot.queryParamMap.get("download_type");
        this.Form = this.fb.group({
            subject: ['', [Validators.required]],
            message: ['', [Validators.required]],
            name: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*")]],
            email: ['', [Validators.required, Validators.email]],
            to_email: ['rfp@reinitresearch.com']
        });
    }

    submitForm() {
        this.submitted = true

        if (this.Form.invalid) {
            return
        }

        const name = this.Form.value.name;
        const email = this.Form.value.email;
        const subject = this.Form.value.subject;
        const message = this.Form.value.message;
        const download_type = this.urlParams.download_type;

        let data = {
            name: name,
            email: email,
            subject: subject,
            message: message,
            download_type: download_type
        };

       console.log(data);

        this.loading = true;
        this.service.requestDownload(data).subscribe(
            res => {
                this.loading = false,
                    this.message = res.success
                this.Form.reset()
            },
            err => {
                this.loading = false,
                this.error = err.error.message
                console.log(err)
            }
        );
    }

}
