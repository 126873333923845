<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>About Reinit Market Research</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">About Us</span>
                        <h2>Empowering Business With Information</h2>
                        <p>Reinit Research provides research and training services to institutions in the public and the private sectors.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-speed"></i> Learn new skills with our bite-sized video tutorials, then test your knowledge with a quick quiz.</li>
                            <li><i class="flaticon-speed"></i> Over 400,000 people have already gained this qualification, and are using it to develop and improve their careers.</li>
                            <li><i class="flaticon-speed"></i> In this introduction to Analytics, we'll show you how to collect and analyse user data and turn it into actionable insights.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-history></app-history>

<section class="team-area-two ptb-100">
    <app-team-style-two></app-team-style-two>

    <div class="shape17"><img src="assets/img/shape/shape17.png" alt="image"></div>
</section>

52666666666666666666666666666666666666666633

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>
