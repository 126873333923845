<style>
    .content h3 {font-size: 18px !important;text-align:center;}
</style>
<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Downloads</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/panel-book.png" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <h3><a href="#">Reinit Research Panel Book</a></h3>
                        <div class="d-flex">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <!-- <a href="/request-download/?download_type='panel-book'" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a> -->
                                    <!-- <a href="/request-download/?download_type='Reinit-Research-PanelBook.pdf'" target="_self" class="default-btn btn-block" ><i class="left-icon flaticon-next-button"></i> Download Now</a> -->
                                    <a href="/assets/downloads/Reinit-Research-PanelBook.pdf" target="_self" class="default-btn btn-block" download><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/esomar.png" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/single-blog">ESOMAR 28 Questions</a></h3>
                        <div class="d-flex align-items-center">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <!-- <a href="/request-download/?download_type='esomar-28-questions'" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a> -->
                                    <a href="/assets/downloads/Updated-Reinit-Esomar28.pdf" target="_self" class="default-btn btn-block" download><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/cert.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a href="#">ESOMAR Certificate</a></h3>
                        <div class="d-flex align-items-center">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <i class="fas fa-download"></i>
                                    <!-- <a href="/request-download/?download_type='esomar-28-certificate'" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a> -->
                                    <a href="/assets/downloads/ESOMAR-2022-Reinit-certificate.pdf" target="_self" class="default-btn btn-block" download><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/panel-book-cert.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a href="#">Reinit Research Credentials</a></h3>
                        <div class="d-flex align-items-center">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <i class="fas fa-download"></i>
                                    <!-- <a href="/request-download/?download_type='esomar-28-certificate'" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a> -->
                                    <a href="/assets/downloads/ReinitResearch_Credentials.pdf" target="_self" class="default-btn btn-block" download><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
