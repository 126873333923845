<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="project-start-image">
                <img src="assets/img/project-start1.png" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="project-start-content">
                <span class="sub-title">Get Started</span>
                <h2>Let's Work On Your Project</h2>
                <p>Having a project in mind that require our services and assistance? Contact us today to get started.</p>
                <a routerLink="/contact" class="default-btn">Get Started</a>
            </div>
        </div>
    </div>
</div>
