<div class="digital-agency-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span class="sub-title wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Market Research</span>
                    <h1 class="wow fadeInLeft" data-wow-delay="10ms" data-wow-duration="1000ms">Platform For Market Research Needs</h1>
                    <p class="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                        We are trend setters in Africa, connecting you to over 400,000 consumers while helping you get in touch with them directly through online research and offline research. <br><br>
                        Reach your target audience and collect quality responses from consumers or business professionals in minutes.
                    </p>
                    <div class="btn-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a routerLink="/about" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets/img/banner-img4.png" class="wow fadeInUp" alt="image" data-wow-delay="00ms" data-wow-duration="1000ms">
                </div>
            </div>
        </div>
    </div>
</div>
