<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Reinit Market Research</span>
            <h2>Market Research At A Glance</h2>
            <p>Reinit Research connects you with one of the largest consumer audience in Africa.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/quantitative-market-research">Quantitative Market Research</a></h3>
                    <p>We target a large consumer audience in Africa through surveys, polls, and questionnaires.</p>
                    <a routerLink="/quantitative-market-research" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-keywords"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/qualitative-market-research">Qualitative Market Research</a></h3>
                    <p>Reinit Research conducts research based open ended questions that relies on focus group discussions, in-depth interviews etc.</p>
                    <a routerLink="/qualitative-market-research" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/our-data-collection-methods">Our Data Collection Methods</a></h3>
                    <p>Our choice of data collection methods for your study entirely depend on your needs. </p>
                    <a routerLink="/our-data-collection-methods" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content-management"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3><a routerLink="/our-coverage">Our Coverage</a></h3>
                    <p>With our massive coverage in Africa, you are always guaranteed fast and quality responses.</p>
                    <a routerLink="/our-coverage" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> Learn More <i class="right-icon flaticon-next-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
